<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			<el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="关键词" label-width="70px">
					<el-input v-model="queryForm.keyword" placeholder="请输入名称/手机号" type="text" clearable
						style="width: 230px" />
				</el-form-item>
				<el-form-item label="等级" label-width="50px">
					<el-select v-model="queryForm.grade" placeholder="请选择等级" size="small" class="w-100">
					   <el-option label="全部" value=""></el-option>
					   <el-option v-for="(item,index) in grades" :key="index"
						 :label="item.name"
						 :value="item.id">
					     </el-option>
					   </el-select>
					</el-form-item>
				</el-form-item>
<!-- 				<el-form-item label="代理" label-width="60px" prop="role" >
					<el-select v-model="queryForm.role" placeholder="请选择代理" size="medium" class="w-100">
						<el-option label="无" value=""></el-option>
						<el-option label="省代" value="1"></el-option>
						<el-option label="市代" value="2"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
					<el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
					<el-button type="primary" icon="el-icon-download" size="mini" @click="download">下载导入模版</el-button>
					<el-upload
						  class="upload-demo d-inline-block"
						  ref="upload"
						  action="#"
						  :on-change="importExcel"
						  :on-success="importSuccess"
						  :on-error="importError"
						  :before-upload="beforeImportUpload"
						  :limit="1"
						  :multiple="false"
						  :show-file-list="false"
						  accept=".xls, .xlsx"
						  :auto-upload="false"
						  >
					   <el-button slot="trigger" icon="el-icon-upload2" size="small" type="primary">导入用户</el-button>
					</el-upload>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click.stop="handleAdd" v-if="queryForm.store_id == ''">新增</el-button>
				<!-- <el-button icon="el-icon-download" size="mini" type="primary" @click="downloadExcel">导出</el-button> -->
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			<el-table v-loading="loading" size="small" :data="list" element-loading-text="Loading" highlight-current-row
				style="width: 100%">
<!-- 				<el-table-column label="序号" width="80" align="center">
					<template slot-scope="scope">
						{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
					</template>
				</el-table-column> -->
				<el-table-column label="ID" width="80" align="center">
					<template slot-scope="scope">
						{{ scope.row.id }}
					</template>
				</el-table-column>
				<el-table-column label="头像" min-width="110" align="center">
				  <template slot-scope="scope">
					<img :src="scope.row.avatar" style="width: 30px;height: 30px;" @click="viewImage(scope.row,'avatar')" v-if="scope.row.avatar">
					<img src="@/assets/images/empty.png" style="width: 30px;height: 30px;" v-else>
				  </template>
				</el-table-column>
				<el-table-column label="用户名" min-width="120" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.nickname }}</span>
					</template>
				</el-table-column>
				<el-table-column label="手机" min-width="130" align="center" prop="mobile"></el-table-column>
				<el-table-column label="级别" min-width="110" align="center" prop="grade">
					<template slot-scope="scope">
						<span>{{ scope.row.grade_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="积分" min-width="110" align="center" prop="balance">
					<template slot-scope="scope">
					  <a @click="recordQuery('balance', scope.row.id)" style="color: #2d8cf0;cursor: pointer;">{{ scope.row.balance || '0.00'}}</a>
				    </template>
				</el-table-column>
	<!-- 			<el-table-column label="积分" min-width="110" align="center" prop="point">
					<template slot-scope="scope">
					  <a @click="recordQuery('point', scope.row.id)" style="color: #2d8cf0;cursor: pointer;">{{ scope.row.point || '0.00'}}</a>
				    </template>
				</el-table-column> -->
				<el-table-column label="个人业绩" min-width="130" align="center" prop="nums"></el-table-column>
				<el-table-column label="团队业绩" min-width="130" align="center" prop="group_nums"></el-table-column>
				<el-table-column label="团队" min-width="130" align="center" prop="group"></el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
						<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="180">
					<template slot-scope="scope">
						<i class="el-icon-time" />
						<span>{{ scope.row.ctime }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="180" align="center" fixed="right">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" content="查看" placement="top">
							<el-button type="success" icon="el-icon-view" size="mini" circle
								@click.stop="handleView(scope.row)"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="编辑" placement="top">
							<el-button type="primary" icon="el-icon-edit" size="mini" circle
								@click.stop="handleEdit(scope.row)"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="积分" placement="top">
							<el-button type="warning" icon="el-icon-help" size="mini" circle
								@click.stop="handleBalance(scope.row)"></el-button>
						</el-tooltip>
	<!-- 					<el-tooltip class="item" effect="dark" content="积分" placement="top">
							<el-button type="warning" icon="el-icon-coin" size="mini" circle
								@click.stop="handlePoint(scope.row)"></el-button>
						</el-tooltip> -->
						<el-tooltip class="item" effect="dark" content="删除" placement="top">
							<el-button type="danger" icon="el-icon-delete" size="mini" circle
								@click.stop="handleDelete(scope.row.id)"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<pagination :total="total" auto-scroll :page.sync="queryForm.page" :limit.sync="queryForm.limit"
				@pagination="query" />
		</el-card>
        <!-- 编辑-->
        <el-dialog :visible.sync="open" width="800px" :close-on-click-modal="false" append-to-body :title="title">
          <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
			<el-row>
				<el-col :span="12">
					<el-form-item label="名称" prop="username">
					  <el-input v-model="form.username" placeholder="请输入名称" type="text" clearable />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="昵称" prop="nickname">
					  <el-input v-model="form.nickname" placeholder="请输入昵称" type="text" clearable />
					</el-form-item>
				</el-col>
			</el-row>
            <el-row>
            	<el-col :span="12">
            		<el-form-item label="电话" prop="mobile">
            		  <el-input v-model="form.mobile" placeholder="请输入电话" type="number" clearable />
            		</el-form-item>
            	</el-col>
            	<el-col :span="12">
            		<el-form-item label="密码" prop="password">
            		  <el-input v-model="form.password" placeholder="请输入密码" type="password" clearable />
            		</el-form-item>
            	</el-col>
            </el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="等级" prop="grade">
					  <el-select v-model="form.grade" placeholder="请选择会员等级" class="w-100">
					  	<el-option label="无" :value="0"></el-option>
					  	<el-option v-for="(item,index) in grades" :key="index"
					  		:label="item.name" 
					  		:value="item.id">
					  	</el-option>
					  </el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="父级" prop="pid">
					  <el-input :value="form.pname" placeholder="请输入父级ID" type="text" clearable @focus="selUsers"/>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="团队" prop="group_id">
					  <el-select v-model="form.group_id" placeholder="请选择团队" class="w-100">
					  	<el-option label="无" :value="0"></el-option>
					  	<el-option v-for="(item,index) in groups" :key="index"
					  		:label="item.name" 
					  		:value="item.id">
					  	</el-option>
					  </el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="业绩" prop="nums">
					  <el-input v-model="form.nums" placeholder="请输入业绩" type="number" clearable/>
					</el-form-item>
				</el-col>
			</el-row>
            <el-row>
            	<el-col :span="12">
            		<el-form-item label="性别">
            		   <el-radio-group v-model="form.gender" class="mt-1">
            		   	<el-radio :label="1">男</el-radio>
            		   	<el-radio :label="2">女</el-radio>
            		   	<el-radio :label="3">未知</el-radio>
            		   </el-radio-group>
            		</el-form-item>
            	</el-col>
            	<el-col :span="12">
            		<el-form-item  label="状态">
            		   <el-radio-group v-model="form.status" class="mt-1">
            		   	<el-radio :label="1">使用</el-radio>
            		   	<el-radio :label="2">停用</el-radio>
            		   </el-radio-group>
            		</el-form-item>
            	</el-col>
            </el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="区域" prop="area_id">
					  <el-cascader
						size="large"
						:options="options"
						v-model="selectedOptions"
						@change="handleChange">
					  </el-cascader>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="头像" prop="avatar">
						<div class="border rounded flex-all mr-3" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;"
						@click="chooseImage"
						>
						  <img :src="form.avatar" class="w-100 h-100" v-if="form.avatar">
						  <i class="el-icon-plus text-muted" style="font-size: 30px;" v-else></i>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item  label="备注">
					   <el-input v-model="form.remarks" placeholder="请输入备注" type="textarea" :rows="3" />
					</el-form-item>
				</el-col>
			</el-row>           
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button size="mini" @click="cancel">取 消</el-button>
            <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
          </div>
        </el-dialog>
		<!-- 选择用户 -->
		 <el-dialog :visible.sync="openUser" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<el-table
			  :data="usersData"
			  style="width: 100%">
			  <el-table-column
			  label="ID"
			  align="center"
			  prop="id">
			  </el-table-column>
			  <el-table-column
			  prop="url"
			  align="center"
			  label="头像">
			  <template slot-scope="scope">
			   <img :src="scope.row.url" style="width: 30px;height: 30px;cursor: pointer;" @click="viewImage(scope.row,'url')" v-if="scope.row.url">
			   <img src="@/assets/images/empty.png" style="width: 30px;height: 30px;cursor: pointer;" v-else>
			  </template>
			  </el-table-column>
			  <el-table-column
			  label="名称"
			  align="center"
			  prop="username">
			  </el-table-column>
			  <el-table-column
			  label="手机号"
			  align="center"
			  prop="mobile">
			  </el-table-column>
			  <el-table-column
			  width="200"
			  align="right">
			  <template slot="header" slot-scope="scope">
				<el-input
				v-model="page.keyword"
				size="mini"
				placeholder="输入关键字搜索">
				  <el-button slot="append" icon="el-icon-search" size="mini" @click="getUsers"></el-button>
				</el-input>
			  </template>
			  <template slot-scope="scope">
				<el-button
				type="danger"
				size="mini"
				@click="handleSel(scope.$index, scope.row)">选择</el-button>
			  </template>
			  </el-table-column>
			</el-table>
			<!-- 分页 -->
			<span slot="footer" class="dialog-footer">
			  <div class="px-2" style="flex: 1;">
			   <pagination
				 :total="page.total"
				 auto-scroll
				 :page.sync="page.page"
				 :limit.sync="page.limit"
				 @pagination="getUsers"
			   />
			  </div>
			</span>
		 </el-dialog>
		 <!-- 修改余额 -->
		 <el-dialog :visible.sync="openBalance" width="600px" :close-on-click-modal="false" append-to-body :title="title">
			 <el-form ref="balanceform" :model="balanceform" :rules="balanceRules" label-width="80px" size="small">
			   <el-form-item label="当前积分" prop="balance">
				   <template>
					 {{balanceform.balance || 0}}
				   </template>
			   </el-form-item>
			   <el-form-item label="积分变动" prop="value">
				   <el-input v-model="balanceform.value" placeholder="请输入积分" type="number" clearable />
				   <small class="text-danger">正数为加，负数为减</small>
			   </el-form-item>
			   <el-form-item label="变动说明" prop="desc">
				   <el-input v-model="balanceform.desc" placeholder="请输入变动说明" type="text" clearable />
			   </el-form-item>
			 </el-form>
			 <div slot="footer" class="dialog-footer">
			   <el-button size="mini" @click="cancelBalance">取 消</el-button>
			   <el-button type="primary" size="mini" @click="handleBalanceSubmit">确 定</el-button>
			 </div>
		 </el-dialog>
		 <!-- 修改积分 -->
		 <el-dialog :visible.sync="openPoint" width="600px" :close-on-click-modal="false" append-to-body :title="title">
			 <el-form ref="pointform" :model="pointform" :rules="pointRules" label-width="80px" size="small">
			   <el-form-item label="当前积分" prop="balance">
				   <template>
					 {{pointform.balance || 0}}
				   </template>
			   </el-form-item>
			   <el-form-item label="积分变动" prop="value">
				   <el-input v-model="pointform.value" placeholder="请输入积分" type="number" clearable />
				   <small class="text-danger">正数为加，负数为减</small>
			   </el-form-item>
			   <el-form-item label="变动说明" prop="desc">
				   <el-input v-model="pointform.desc" placeholder="请输入变动说明" type="text" clearable />
			   </el-form-item>
			 </el-form>
			 <div slot="footer" class="dialog-footer">
			   <el-button size="mini" @click="openPoint = false">取 消</el-button>
			   <el-button type="primary" size="mini" @click="handlePointSubmit">确 定</el-button>
			 </div>
		 </el-dialog>
		 <!-- 日志列表 -->
		 <el-dialog
			 :visible.sync="record_open"
			 width="950px"
			 :close-on-click-modal="false"
			 append-to-body
			 title="日志"
		   >
			 <el-table
			   v-loading="listLoading"
			   size="small"
			   :data="recordList"
			   element-loading-text="Loading"
			   highlight-current-row
			   style="width: 100%"
			 >
			   <el-table-column label="ID" width="80" align="center">
				 <template slot-scope="scope">
				   {{ scope.row.id }}
				 </template>
			   </el-table-column>
			   <el-table-column label="描述" align="center">
				 <template slot-scope="scope">
				   <span>{{ scope.row.memo }}</span>
				 </template>
			   </el-table-column>
			   <el-table-column label="变更数量" align="center">
				 <template slot-scope="scope">
				   <span>{{ scope.row.money || 0}}</span>
				 </template>
			   </el-table-column>
			   <el-table-column label="余额" align="center">
				 <template slot-scope="scope">
				   <span>{{ scope.row.balance || 0}}</span>
				 </template>
			   </el-table-column>
			   <el-table-column label="资源id" align="center">
				 <template slot-scope="scope">
				   <span>{{ scope.row.source_id || 0}}</span>
				 </template>
			   </el-table-column>
			   <el-table-column align="center" label="变更时间">
				 <template slot-scope="scope">
				   <span>{{ scope.row.ctime }}</span>
				 </template>
			   </el-table-column>
			 </el-table>
			 <pagination
			   :total="recordForm.total"
			   auto-scroll
			   :page.sync="recordForm.page"
			   :limit.sync="recordForm.limit"
			   @pagination="recordQuery"
			 />
			 <div slot="footer" class="dialog-footer"></div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
	export default {
		inject: ['app'],
		mixins: [common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'user',
				queryForm: {
					page: 1,
					limit: 10,
					grade:'',
					role:'',
					keyword: '',
					store_id:this.$cookies.get('store_id') || ''
				},
				form:{
					username:'',
					nickname:'',
					password:'',
					mobile:'',
					gender:3,
					grade:'',
					pid:'',
					pname:'',
					remarks:'',
					status:1,
					sort:100,
					avatar:'',
					url:'',
					province:'',
					city:'',
					country:'',
					area_id:'',
					group_id:'',
					nums:0,
				},
				rules:{
					username: [
					  { required: true, message: '请输入名称', trigger: 'blur' }
					],
					mobile: [
					  { required: true, message: '请输入电话', trigger: ['blur', 'change'] }
					],
					grade: [
					  { required: true, message: '请选择等级', trigger: ['blur', 'change'] }
					],
				},
				options: regionData,
				areaOptions: [],
				grades:[],
				openUser:false,
				usersData:[],
				page: {
					page: 1,
					limit: 10,
					total:0,
					keyword: '',
				},
				openBalance:false,
				balanceform:{
					id:'',
					balance:0,
					value:'',
					desc:'',
				},
				balanceRules:{
					value: [
					  { required: true, message: '请输入值', trigger: 'blur' }
					],
				},
				openPoint:false,
				pointform:{
					id:'',
					balance:0,
					value:'',
					desc:'',
				},
				pointRules:{
					value: [
					  { required: true, message: '请输入值', trigger: 'blur' }
					],
				},
				record_open:false,
				recordList:[],
				listLoading: false,
				recordForm: {
					page: 1,
					limit: 10,
					total:0,
					name:'',
					id:'',
				},
				province:"",
				city:"",
				country:"",
				selectedOptions: [],
				options: regionData,
				groups:[],
			}
		},
		mounted() {
           this.getGrades()
           this.getGroups()
		},
		methods: {
			reset() {
			  this.form = {
				   username:'',
				   nickname:'',
				   password:'',
				   mobile:'',
				   gender:3,
				   grade:'',
				   pid:'',
				   pname:'',
				   remarks:'',
				   status:1,
				   sort:100,
				   avatar:'',
				   url:'',
				   province:'',
				   city:'',
				   country:'',
				   area_id:'',
				   group_id:'',
				   nums:0,
			  }
			  this.resetForm('form')
			},
			getGrades(){
				this.axios.get('/manage/user_grade/all').then(res=>{
				  if(res.status){
					let  grades = res.data
					this.grades = grades
				  }
				})
			},
			getGroups(){
				this.axios.get('/manage/group/all').then(res=>{
				  if(res.status){
					let  groups = res.data
					this.groups = groups
				  }
				})
			},
			handleChange() {
				this.province= CodeToText[this.selectedOptions[0]]
				this.city= CodeToText[this.selectedOptions[1]]
				this.country= CodeToText[this.selectedOptions[2]]
				this.form.province = CodeToText[this.selectedOptions[0]]
				this.form.city = CodeToText[this.selectedOptions[1]]
				this.form.country = CodeToText[this.selectedOptions[2]]
				this.form.area_id= this.selectedOptions[2]
			},
			handleEdit(row) {
				console.log(row)
				this.reset()
				this.open = true
				this.title = '编辑'
				this.form.id = row.id
				this.form.password = ''
				this.form.grade = parseInt(row.grade)
				this.form.username = row.username
				this.form.nickname = row.nickname
				this.form.mobile = row.mobile
				this.form.gender = row.gender
				this.form.avatar = row.avatar
				this.form.url = row.url
				this.form.status = row.status
				this.form.remarks = row.remarks
				this.form.pid = row.pid
				this.form.group_id = row.group_id
				this.form.nums = row.nums
				this.selectedOptions = []
				if(row.area_ids){
					this.province = row.province
					this.city = row.city
					this.country = row.country
					this.selectedOptions.push(
						TextToCode[this.province].code,
						TextToCode[this.province][this.city].code,
						TextToCode[this.province][this.city][this.country].code
					);
				}
			},
			handleView(row){
				this.$router.push({
				  path:"/user_detail",
				  query: {
					  id:row.id
				  }
				})
			},
			selUsers(){
				 this.getUsers()
				 this.$nextTick(()=>{
				   this.title = '用户列表'
				   this.openUser = true
				 })
			},
			getUsers(){
				this.axios.get('/manage/user/list',{
				  params: {
					page:this.page.page,
					limit:this.page.limit,
					keyword:this.page.keyword,
				  }
				}).then(res=>{
				  this.page.total = res.total
				  this.usersData = res.data
				})
			},
			handleSel(index,item){
				this.form.pid = item.id
				this.form.pname = item.username ? item.username : item.mobile
				this.openUser = false
				this.title = '新增'
			},
			chooseImage(){
				this.app.chooseImage((res)=>{
				  this.form.avatar = res[0].url
				})
			},
			handleBalance(item){
				this.balanceform = {
					id:'',
					balance:item.balance,
					value:'',
					desc:'',
				}
				this.title = '修改积分'
				this.balanceform.id = item.id
				this.openBalance = true
			},
			cancelBalance(){
				this.openBalance = false
				this.title = ''
			},
			handleBalanceSubmit(){
				this.$refs.balanceform.validate(valid => {
				   if (valid) {
					    this.axios.post('/manage/user/edit_balance',this.balanceform).then(res=>{
						  if(res.status){
							this.$message.success('修改成功')
							this.openBalance = false
							this.title = ''
							this.getList()
						  }else{
							this.$message.error(res.msg)
						  }
						})
				   }
				})
			},
			handlePoint(item){
				this.pointform = {
					id:'',
					balance:item.point,
					value:'',
					desc:'',
				}
				this.title = '修改购物积分'
				this.pointform.id = item.id
				this.openPoint = true
			},
			handlePointSubmit(){
				this.$refs.pointform.validate(valid => {
				   if (valid) {
					    this.axios.post('/manage/user/edit_point',this.pointform).then(res=>{
						  if(res.status){
							this.$message.success('修改成功')
							this.openPoint = false
							this.title = ''
							this.getList()
						  }else{
							this.$message.error(res.msg)
						  }
						})
				   }
				})
			},
			recordQuery(name,id){
				 this.record_open = true;
				 this.recordList = [];
				 if(id){
				   this.recordForm.id = id
				 }
				 if(name.constructor !== Object){
					this.recordForm.name = name
				 }
				 this.axios.get('/manage/user/recordList',{
				   params:this.recordForm
				 }).then(res=>{
				   if(res.status){
					 this.recordList = res.data.data
					 this.recordForm.total = res.data.total
				   }
				 })
			},
			download(){
				this.axios.get('/manage/user/downloadExcel',{
					params:this.queryForm,
					responseType: 'arraybuffer',
				}).then(res=>{
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = '导入模版.xlsx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
			importExcel(e){
				//获取用户选择的文件
				const file = e.raw
				let formdata = new FormData()
				formdata.append('file', file)
				this.axios.post('/manage/user/importExport',formdata).then(res=>{
					if(res.status){
						this.$message.success('导入成功')
						this.getList()
					}else{
						this.$message.success('导入失败')
					}
					this.$refs.upload.clearFiles()
				})
			},
			beforeImportUpload(file){
				  let fileArr = file.name.split('.')
				  let suffix = fileArr[fileArr.length - 1]
				  //只能导入.xls和.xlsx文件
				  if (!/(xls|xlsx)/i.test(suffix)) {
					this.$message('文件格式不正确')
					return false
				  }
				  return true
			},
		}
	}
</script>

<style scoped>
:deep .el-cascader .el-input .el-input__inner{
	margin-bottom: 8px!important;
}
</style>